import { Row, type ColumnDef } from "@tanstack/react-table";
import { MoreHorizontal } from "lucide-react";
import {
  apiFetch,
  createNotificacionForAction,
} from "@/services/siniestroService";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "../ui/button";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../ui/hover-card";
import { SolarCardOutline } from "../icons/SolarCardOutline";
import { toast } from "sonner";
import {
  addAccionToSiniestro,
  fetchBienesSiniestro,
  updateBienInstance,
} from "@/services/siniestroService";
import { memo, useCallback, useEffect } from "react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command";
import { MingcuteEye2Line } from "../icons/MingcuteEye2Line";
import { MaterialSymbolsLightBookmarkManagerSharp } from "../icons/MaterialSymbolsLightBookmarkManagerSharp";
import { AntDesignIdcardOutlined } from "../icons/AntDesignIdcardOutlined";
import { Badge } from "../ui/badge";
import React from "react";
import { useSiniestroStore } from "@/stores/siniestroStore";
import { useEstadoStore } from "@/stores/estadoStore";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";
import { Skeleton } from "../ui/skeleton";
import { useAuth } from "@/contexts/AuthContext";
import { SkeletonRow } from "../ui/SkeletonRow";

import {
  AiOutlineCalendar,
  AiOutlineFileDone,
  AiOutlinePhone,
  AiOutlineTool,
} from "react-icons/ai";
import {
  BsCalendarCheck,
  BsCardChecklist,
  BsFileEarmarkLock,
} from "react-icons/bs";
import {
  FaFileInvoice,
  FaRegClock,
  FaHandsHelping,
  FaBoxOpen,
  FaThumbsUp,
} from "react-icons/fa";
import { MdOutlineAssignmentTurnedIn, MdOutlineUpdate } from "react-icons/md";
import {
  RiFileSearchLine,
  RiFileHistoryLine,
  RiContactsLine,
} from "react-icons/ri";
import { format } from "@formkit/tempo";
import { fetchUserRoles } from "@/services/userService";

function IconoPorEstado(estado: string): React.ReactNode {
  switch (estado) {
    case "Nuevo":
      return <AiOutlineFileDone />;
    case "Coordinando visita":
      return <FaHandsHelping />;
    case "Telefónico":
      return <AiOutlinePhone />;
    case "En proceso":
      return <MdOutlineUpdate />;
    case "Informe pendiente":
      return <BsCardChecklist />;
    case "Informe final":
      return <MdOutlineAssignmentTurnedIn />;
    case "Cerrado":
      return <BsFileEarmarkLock />;
    case "Reabierto":
      return <RiFileHistoryLine />;
    case "Analizando doc.":
      return <RiFileSearchLine />;
    case "Prescripción":
      return <FaFileInvoice />;
    case "Pre-liquidación":
      return <BsCalendarCheck />;
    case "Espera rta. cía.":
      return <FaRegClock />;
    case "Primer contacto":
      return <AiOutlinePhone />;
    case "Verificación técnica":
      return <AiOutlineTool />;
    case "Pre-informe":
      return <RiFileSearchLine />;
    case "Se mandó conforme":
      return <FaThumbsUp />;
    case "Reclamando doc.":
      return <BsCardChecklist />;
    case "Reclamando doc. segunda vez":
      return <BsCardChecklist />;
    case "Reclamando contacto":
      return <RiContactsLine />;
    case "Reclamando conformidad":
      return <AiOutlineFileDone />;
    case "Visita coordinada":
      return <AiOutlineCalendar />;
    default:
      return null;
  }
}

type BadgeVariant =
  | "success"
  | "yellow"
  | "destructive"
  | "default"
  | "outline"
  | "secondary"
  | "black"
  | "prescription";

function obtenerEstadoDeActualizacion(
  fechaAccion: string,
  estado: string
): {
  etiqueta: string;
  variant: BadgeVariant;
} {
  if (estado === "Prescripción") {
    return { etiqueta: "Prescripción", variant: "prescription" };
  }

  if (estado === "Cerrado") {
    return { etiqueta: "Cerrado", variant: "black" };
  }

  if (estado === "Espera rta. cía.") {
    return { etiqueta: "Esperando respuesta", variant: "outline" };
  }

  const fecha = new Date(fechaAccion);
  const hoy = new Date();
  const diasTranscurridos = Math.floor(
    (hoy.getTime() - fecha.getTime()) / (1000 * 3600 * 24)
  );

  if (diasTranscurridos < 5) {
    return { etiqueta: "Al día", variant: "success" };
  } else if (diasTranscurridos <= 7) {
    return { etiqueta: "Moderado", variant: "yellow" };
  } else {
    return { etiqueta: "Atrasado", variant: "destructive" };
  }
}

function obtenerFechaUltimaAccion(siniestro?: Siniestro): string {
  if (!siniestro) {
    return "Sin acciones";
  }

  const fechaAccion = siniestro.ultimaAccionFecha;

  if (fechaAccion !== "9999-12-31T23:59:59.999Z") {
    return format(new Date(fechaAccion), "DD/MM/YYYY");
  } else {
    return "Sin acciones";
  }
}

function toTitleCase(str: string) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

interface ActionCellProps {
  row: Row<Siniestro>;
  updateSiniestro: (id: number, updatedFields: Partial<Siniestro>) => void;
}

export const ActionCell: React.FC<ActionCellProps> = memo(
  ({ row, updateSiniestro }) => {
    const { estados, tramitadores, fetchEstadosYTramitadores } =
      useEstadoStore();
    const { roles } = useAuth(); // Usa el contexto de autenticación
    const userRoles = Array.isArray(roles) ? roles : []; // Asegúrate de que roles es un array
    const hasRole2 = userRoles.includes(2); // Verifica si el usuario tiene el rol 2
    const isClosed = row.original.stateName === "Cerrado";
    const hasRole11 = userRoles.includes(11); // Verifica si el usuario tiene el rol 11
    const hasRole7 = userRoles.includes(7);
    const hasRole5 = userRoles.includes(5);
    const isTramitadorRestricted =
      hasRole11 &&
      ["norte", "sur", "oeste", "caba"].includes(
        row.original.responsibleName?.toLowerCase()
      );

    const {
      setEditingSiniestroId,
      setIsUpdatingState,
      isUpdatingState,
      editingSiniestroId,
    } = useSiniestroStore();

    useEffect(() => {
      void fetchEstadosYTramitadores();
    }, [fetchEstadosYTramitadores]);

    const openSiniestroDetails = () => {
      const siniestroId = row.original.id;
      const url = `/siniestros/${siniestroId}/detalles`;
      window.open(url, "_blank");
    };

    const handleInstanceChange = async (
      bienId: number,
      newInstanceId: number
    ) => {
      try {
        await updateBienInstance(row.original.id, bienId, newInstanceId);
      } catch (error) {
        toast.error("Error al actualizar la instancia del bien.");
      }
    };

    const handleReopenSiniestro = async () => {
      await handleStateChange(8, false);
      await addAccionToSiniestro(row.original.id, 53, null, null);
      toast.success("Siniestro reabierto con éxito.");
    };

    const handleStateChange = useCallback(
      async (newEstadoId: number, shouldRegisterAction = true) => {
        setIsUpdatingState(true);
        setEditingSiniestroId(row.original.id);

        try {
          // Verificar si se está intentando cerrar el siniestro (estado 7)
          if (newEstadoId === 7) {
            // Si el usuario no tiene el rol 2, verificar la factura
            if (!userRoles.includes(2)) {
              // Verificar si el siniestro no es del tipo "G"
              if (row.original.type !== "G") {
                try {
                  const response = await apiFetch(
                    `/api/facturas/check/${row.original.id}`,
                    { method: "GET" }
                  );

                  if (!response.ok) {
                    if (response.status === 403) {
                      throw new Error("Forbidden");
                    }
                    throw new Error("Error al verificar la factura");
                  }

                  const { hasFactura } = await response.json();

                  if (!hasFactura) {
                    toast.error(
                      "No puedes cerrar el siniestro ya que no tiene una factura asignada."
                    );
                    setIsUpdatingState(false);
                    setEditingSiniestroId(null);
                    return;
                  }
                } catch (error) {
                  if (error instanceof Error && error.message === "Forbidden") {
                    toast.error(
                      "No tienes permiso para cerrar este siniestro."
                    );
                  } else {
                    toast.error("Error al verificar la factura del siniestro.");
                  }
                  setIsUpdatingState(false);
                  setEditingSiniestroId(null);
                  return;
                }
              }
            }
          }

          const updateData: { estadoId: number; closedAt?: string } = {
            estadoId: newEstadoId,
          };
          let idActionType = -1;

          // Asignar ID de acción según el nuevo estado
          switch (newEstadoId) {
            case 2: // Coordinando visita
              idActionType = 37;
              break;
            case 3: // Telefónico
              idActionType = 38;
              break;
            case 7: // Cerrado
              idActionType = 39;
              updateData.closedAt = new Date().toISOString();
              break;
            case 4: // En proceso
              idActionType = 550;
              break;
            case 12: // Espera rta. cía.
              idActionType = 553;
              break;
            case 10: // Prescripción
              idActionType = 564;
              break;
            case 11: // Pre-liquidación
              idActionType = 123;
              break;
            case 13: // Primer contacto
              idActionType = 554;
              break;
            case 14: // Verificación técnica
              idActionType = 555;
              break;
            case 15: // Reclamando contacto
              idActionType = 563;
              break;
            case 16: // Se mandó conforme
              idActionType = 557;
              break;
            case 17: // Reclamando doc.
              idActionType = 558;
              break;
            case 18: // Reclamando doc. segunda vez
              idActionType = 559;
              break;
            case 19: // Reclamando conformidad
              idActionType = 562;
              break;
            case 20: // Visita coordinada
              idActionType = 561;
              break;
            default:
              break;
          }

          const response = await apiFetch(
            `/api/siniestros/${row.original.id}`,
            {
              method: "PATCH",
              body: JSON.stringify(updateData),
            }
          );

          if (!response.ok) {
            throw new Error("Error al actualizar el estado del siniestro");
          }

          if (response.ok && shouldRegisterAction && idActionType !== -1) {
            await tryAddAccionToSiniestro({
              siniestroId: row.original.id,
              idActionType,
              userAssignedId: null,
              itemId: null,
            });
          }

          if ([7, 8].includes(newEstadoId)) {
            const fetchedBienes = await fetchBienesSiniestro(row.original.id);
            await Promise.all(
              fetchedBienes.map((bien: Bien) =>
                newEstadoId === 7
                  ? handleInstanceChange(bien.id, 10)
                  : handleInstanceChange(bien.id, 1)
              )
            );
          }

          updateSiniestro(row.original.id, {
            stateName: estados.find((estado) => estado.id === newEstadoId)
              ?.name,
          });

          toast.success("Estado del siniestro actualizado.");
        } catch (error) {
          toast.error("Error al actualizar el estado del siniestro.");
        } finally {
          setIsUpdatingState(false);
          setEditingSiniestroId(null);
        }
      },
      [
        row.original.id,
        estados,
        updateSiniestro,
        handleInstanceChange,
        userRoles,
      ]
    );

    async function tryAddAccionToSiniestro({
      siniestroId,
      idActionType,
      userAssignedId,
      itemId,
      maxRetries = 5,
    }: {
      siniestroId: number;
      idActionType: number;
      userAssignedId: number | null;
      itemId: number | null;
      maxRetries?: number;
    }) {
      let attempt = 0;
      let success = false;
      let result;

      while (attempt < maxRetries && !success) {
        try {
          result = await addAccionToSiniestro(
            siniestroId,
            idActionType,
            userAssignedId,
            itemId
          );
          success = true;
        } catch (error) {
          attempt++;
          if (attempt >= maxRetries) {
            toast.error(
              "Error al agregar la acción al siniestro después de varios intentos."
            );
            throw error;
          }
        }
      }

      return result;
    }

    const handleTramitadorChange = async (newTramitadorId: number) => {
      setIsUpdatingState(true);
      setEditingSiniestroId(row.original.id);

      try {
        const response = await apiFetch(
          `/api/siniestros/${row.original.id}/tramitador`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ tramitadorId: newTramitadorId }),
          }
        );

        if (!response.ok) {
          throw new Error("Error al actualizar el tramitador del siniestro");
        }

        if (response.ok) {
          // Obtener los roles del nuevo tramitador
          const { roles } = await fetchUserRoles(newTramitadorId);

          await addAccionToSiniestro(
            row.original.id,
            101,
            newTramitadorId,
            null
          );

          // Verificar si el usuario no tiene el rol de Taller (ID 12)
          if (!roles.includes(12)) {
            await createNotificacionForAction(
              newTramitadorId,
              101,
              row.original.id,
              roles[0] // Pasamos el primer rol del usuario como ejemplo
            );
          }

          updateSiniestro(row.original.id, {
            responsibleName: tramitadores.find(
              (tramitador) => tramitador.id === newTramitadorId
            )?.display_name,
          });
          toast.success("Tramitador asignado correctamente.");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Error al actualizar el tramitador del siniestro.");
      } finally {
        setIsUpdatingState(false);
        setEditingSiniestroId(null);
      }
    };

    return (
      <div className="flex items-center">
        <TooltipProvider delayDuration={50}>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                className="h-8 w-8 p-0"
                onClick={openSiniestroDetails}
              >
                <span className="sr-only">Ver detalles</span>
                <MingcuteEye2Line className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Ver detalles</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        {isUpdatingState && row.original.id === editingSiniestroId ? (
          <SkeletonRow columnsCount={10} />
        ) : (
          !hasRole7 &&
          (!isClosed || (isClosed && hasRole2)) && ( // Oculta el dropdown si el siniestro está cerrado y el usuario no tiene el rol 2
            <DropdownMenu modal={false}>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Abrir menú</span>
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                {!isClosed && !isTramitadorRestricted && (
                  <>
                    <DropdownMenuSub>
                      <DropdownMenuSubTrigger>
                        <MaterialSymbolsLightBookmarkManagerSharp className="h-4 w-4 mr-2" />
                        Estado
                      </DropdownMenuSubTrigger>
                      <DropdownMenuSubContent>
                        {estados
                          .filter((estado) => {
                            // Si el usuario tiene el rol 11, mostrar los estados 2, 20, y 14
                            if (hasRole11) {
                              return [2, 20, 14].includes(estado.id);
                            }
                            // Si el usuario tiene el rol 5, mostrar solo los estados 2 y 20
                            if (hasRole5) {
                              return [2, 20, 12, 14].includes(estado.id);
                            }
                            // Filtrar y ocultar los estados con id 4, 8 y 6 para todos los demás
                            return (
                              estado.id !== 4 &&
                              estado.id !== 6 &&
                              estado.id !== 8
                            );
                          })
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((estado) => (
                            <DropdownMenuItem
                              key={estado.id}
                              onSelect={async () => {
                                if (estado.name !== row.original.stateName) {
                                  await handleStateChange(estado.id);
                                }
                              }}
                              disabled={estado.name === row.original.stateName} // Deshabilita si es el estado actual
                            >
                              <div className="flex items-center gap-2">
                                {IconoPorEstado(estado.name)}
                                <span>{estado.name}</span>
                              </div>
                            </DropdownMenuItem>
                          ))}
                      </DropdownMenuSubContent>
                    </DropdownMenuSub>
                  </>
                )}
                {isClosed && hasRole2 && (
                  <DropdownMenuItem onSelect={handleReopenSiniestro}>
                    Reabrir
                  </DropdownMenuItem>
                )}
                {!isClosed && !isTramitadorRestricted && (
                  <DropdownMenuSeparator />
                )}
                {!isClosed && (
                  <>
                    <DropdownMenuSub>
                      <DropdownMenuSubTrigger>
                        <AntDesignIdcardOutlined className="h-4 w-4 mr-2" />
                        Tramitador
                      </DropdownMenuSubTrigger>
                      <DropdownMenuSubContent className="p-0">
                        <Command>
                          <CommandInput
                            placeholder="Buscar tramitador"
                            autoFocus={true}
                          />
                          <CommandList>
                            <CommandEmpty>
                              No se encontraron resultados
                            </CommandEmpty>
                            <CommandGroup>
                              {tramitadores.map((tramitador) => (
                                <CommandItem
                                  key={tramitador.id}
                                  onSelect={async () => {
                                    await handleTramitadorChange(tramitador.id);
                                  }}
                                >
                                  {tramitador.display_name}
                                </CommandItem>
                              ))}
                            </CommandGroup>
                          </CommandList>
                        </Command>
                      </DropdownMenuSubContent>
                    </DropdownMenuSub>
                  </>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          )
        )}
      </div>
    );
  }
);

const SkeletonCell: React.FC<{ loading: boolean }> = ({ loading }) => {
  return loading ? <Skeleton className="w-full h-8" /> : null;
};

export const SiniestroColumns = (
  updateSiniestro: (id: number, updatedFields: Partial<Siniestro>) => void
): Array<ColumnDef<Siniestro>> => [
  {
    accessorKey: "companyIncidentNumber",
    header: "Nº de Siniestro",
    cell: (info) => {
      const companyIncidentNumber = String(info.getValue());
      const isGarantia = info.row.original.type === "G";
      const hasPendingRemito = info.row.original.hasPendingRemito;
      const { roles } = useAuth();

      return (
        <div className="flex items-center space-x-2">
          <span>{companyIncidentNumber}</span>
          {isGarantia && <Badge variant="outline">Garantía</Badge>}
          {roles.includes(5) && hasPendingRemito && (
            <Badge variant="destructive">Remito pendiente</Badge>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "insuredName",
    header: "Nombre del asegurado",
    cell: (info) => {
      const insuredNameValue = info.getValue();
      let insuredName = "";
      let insuredDNI = "";

      if (typeof insuredNameValue === "string") {
        insuredName = toTitleCase(insuredNameValue);
        insuredDNI = info.row.original.insuredDNI;
      }

      return (
        <div className="flex items-center">
          <div
            className="inline-flex items-center gap-2"
            style={{ flexWrap: "nowrap" }}
          >
            <div style={{ whiteSpace: "nowrap" }}>{insuredName}</div>
            <HoverCard openDelay={70}>
              <HoverCardTrigger asChild>
                <span className="text-gray-500 hover:text-gray-700">
                  <SolarCardOutline />
                </span>
              </HoverCardTrigger>
              <HoverCardContent>
                <div>
                  <p>DNI: {insuredDNI}</p>
                </div>
              </HoverCardContent>
            </HoverCard>
          </div>
        </div>
      );
    },
  },
  {
    accessorKey: "companyName",
    header: "Compañía",
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: "stateName",
    header: "Estado",
    cell: (info) => {
      const stateName = info.getValue() as string;
      return (
        <div className="flex items-center gap-2">
          {IconoPorEstado(stateName)}
          <span>{stateName}</span>
        </div>
      );
    },
  },
  {
    accessorKey: "bienes",
    header: "Bienes",
    cell: (info) => {
      const bienes = info.getValue() as number;
      return (
        <div className="flex items-center gap-2">
          <FaBoxOpen className="text-gray-600 dark:text-gray-400" />
          <span>{bienes}</span>
        </div>
      );
    },
  },
  {
    accessorKey: "ultimaAccionFecha",
    header: "Última acción",
    cell: (info) => obtenerFechaUltimaAccion(info.row.original),
    sortingFn: (rowA, rowB, columnId) => {
      const dateA = new Date(rowA.getValue(columnId) as string).getTime();
      const dateB = new Date(rowB.getValue(columnId) as string).getTime();
      return dateA - dateB;
    },
  },
  {
    accessorFn: (row) =>
      obtenerEstadoDeActualizacion(row.ultimaAccionFecha, row.stateName),
    id: "estadoActualizacion",
    header: "Estado de actualización",
    cell: (info) => {
      const estado = info.getValue() as {
        etiqueta: string;
        variant: BadgeVariant;
      };
      return <Badge variant={estado.variant}>{estado.etiqueta}</Badge>;
    },
  },
  {
    accessorKey: "responsibleName",
    header: "Tramitador",
  },
  // Nueva columna de Skeleton
  {
    id: "skeleton",
    header: "",
    cell: (info) => <SkeletonCell loading={info.row.original.isUpdating} />, // accedemos a isUpdating desde original
  },
  {
    id: "actions",
    header: "Acciones",
    cell: (context) => (
      <ActionCell row={context.row} updateSiniestro={updateSiniestro} />
    ),
  },
];
