import React, { useEffect, useMemo, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Skeleton } from "@/components/ui/skeleton";
import { format, isValid } from "date-fns";
import { es } from "date-fns/locale";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface SiniestroData {
  companyId: number;
  companyName: string;
  newSiniestros: number;
  fecha: string;
}

interface SiniestrosAreaChartProps {
  data: SiniestroData[];
  isLoading: boolean;
}
const SiniestrosAreaChart: React.FC<SiniestrosAreaChartProps> = ({
  data,
  isLoading,
}) => {
  const companies = useMemo(() => {
    const uniqueCompanies = new Map();
    data.forEach((item) => {
      if (!uniqueCompanies.has(item.companyId)) {
        uniqueCompanies.set(item.companyId, {
          id: item.companyId,
          name: item.companyName,
        });
      }
    });
    return Array.from(uniqueCompanies.values()).sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }, [data]);

  const [selectedCompanyId, setSelectedCompanyId] = useState<string>(
    companies.length > 0 ? companies[0].id.toString() : ""
  );

  // Efecto para actualizar el selectedCompanyId cuando cambian las compañías
  useEffect(() => {
    if (
      companies.length > 0 &&
      !companies.some((c) => c.id.toString() === selectedCompanyId)
    ) {
      setSelectedCompanyId(companies[0].id.toString());
    }
  }, [companies, selectedCompanyId]);

  const filteredData = useMemo(() => {
    return data.filter(
      (item) => item.companyId.toString() === selectedCompanyId
    );
  }, [data, selectedCompanyId]);

  const sortedData = useMemo(
    () =>
      [...filteredData].sort((a, b) => {
        const dateA = new Date(a.fecha);
        const dateB = new Date(b.fecha);
        return isValid(dateA) && isValid(dateB)
          ? dateA.getTime() - dateB.getTime()
          : 0;
      }),
    [filteredData]
  );

  if (isLoading) {
    return <Skeleton className="h-[300px] w-full" />;
  }

  if (data.length === 0) {
    return (
      <div className="h-[300px] flex items-center justify-center">
        No hay datos disponibles para ninguna compañía.
      </div>
    );
  }

  const CompanySelect = () => (
    <Select value={selectedCompanyId} onValueChange={setSelectedCompanyId}>
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Selecciona una compañía" />
      </SelectTrigger>
      <SelectContent className="overflow-y-auto max-h-[20rem]">
        {companies.map((company) => (
          <SelectItem key={company.id} value={company.id.toString()}>
            {company.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );

  if (sortedData.length === 0) {
    return (
      <div className="h-[300px] flex flex-col items-center justify-center">
        <CompanySelect />
        <div className="mt-4">No hay datos disponibles para esta compañía.</div>
      </div>
    );
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    // Ajustamos la fecha sumando un día
    date.setDate(date.getDate() + 1);
    return isValid(date)
      ? format(date, "dd MMM", { locale: es })
      : "Fecha inválida";
  };

  return (
    <div>
      <div className="mb-4">
        <CompanySelect />
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          data={sortedData}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="fecha" tickFormatter={(value) => formatDate(value)} />
          <YAxis allowDecimals={false} domain={[0, "dataMax"]} />
          <Tooltip
            formatter={(value) => [
              `${value} siniestros`,
              sortedData[0]?.companyName,
            ]}
            labelFormatter={(label) => {
              const date = new Date(label);
              // Ajustamos la fecha sumando un día
              date.setDate(date.getDate() + 1);
              return isValid(date)
                ? format(date, "dd 'de' MMMM 'de' yyyy", { locale: es })
                : "Fecha inválida";
            }}
          />
          <Area
            type="monotone"
            dataKey="newSiniestros"
            stroke="hsl(200, 70%, 50%)"
            fill="hsl(200, 70%, 50%)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SiniestrosAreaChart;
