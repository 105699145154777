import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { apiFetch } from "@/services/siniestroService";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

interface ProvidenciaConformityDialogProps {
  siniestro: {
    companyIncidentNumber: string;
    policyNumber: string;
  };
  onClose: () => void;
}

const ProvidenciaConformityDialog: React.FC<
  ProvidenciaConformityDialogProps
> = ({ siniestro, onClose }) => {
  const [monto, setMonto] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const handleExportPdf = async () => {
    setIsLoading(true);
    try {
      const response = await apiFetch("/api/pdf/providencia-conformity", {
        method: "POST",
        body: JSON.stringify({
          siniestroNumber: siniestro.companyIncidentNumber,
          polizaNumber: siniestro.policyNumber,
          monto: parseFloat(monto),
        }),
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Conformidad_Providencia_${siniestro.companyIncidentNumber}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error("Failed to generate PDF");
      }
    } catch (error) {
      console.error("Error exporting PDF:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Generar conformidad Providencia</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <div>
            <Label>Monto de la conformidad:</Label>
            <Input
              type="number"
              min="0"
              step="0.01"
              value={monto}
              onChange={(e) => {
                const value = e.target.value;
                if (
                  value === "" ||
                  (/^\d*\.?\d*$/.test(value) && parseFloat(value) >= 0)
                ) {
                  setMonto(value);
                }
              }}
              placeholder="Ingrese el monto en pesos"
            />
          </div>
        </div>
        <DialogFooter>
          <Button
            onClick={handleExportPdf}
            disabled={isLoading || !monto || parseFloat(monto) <= 0}
          >
            {isLoading ? (
              <>
                <AiOutlineLoading3Quarters className="mr-2 h-4 w-4 animate-spin" />
                Exportando...
              </>
            ) : (
              "Exportar PDF"
            )}
          </Button>
          <Button variant="outline" onClick={onClose}>
            Cerrar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ProvidenciaConformityDialog;
