import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { generarFacturaC, addFactura } from "@/services/facturaService";
import { addAccionToSiniestro } from "@/services/siniestroService";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";

interface GenerarFacturaDialogProps {
  honorarios: Array<{ text: string; value: number; id: number }>;
  siniestroId: number;
  compañiaAseguradora: string;
  onFacturaGenerated: (newFactura: Factura) => void;
  updatePdfExistence: (facturaNumber: string) => void;
}

const BASE_URL =
  import.meta.env.MODE === "production"
    ? import.meta.env.VITE_API_URL_PROD
    : import.meta.env.VITE_API_URL_DEV;

type RubroType = "Servicios" | "Productos";

export function GenerarFacturaDialog({
  honorarios,
  siniestroId,
  compañiaAseguradora,
  onFacturaGenerated,
  updatePdfExistence,
}: GenerarFacturaDialogProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedHonorarios, setSelectedHonorarios] = useState<number[]>([]);
  const [rubro, setRubro] = useState<RubroType>("Servicios");

  const handleHonorarioChange = (honorarioId: number) => {
    setSelectedHonorarios((prev) =>
      prev.includes(honorarioId)
        ? prev.filter((id) => id !== honorarioId)
        : [...prev, honorarioId]
    );
  };

  const handleRubroChange = (value: RubroType) => {
    setRubro(value);
  };

  const handleGenerarFactura = async () => {
    setIsLoading(true);
    try {
      const detalleFactura = honorarios
        .filter((honorario) => selectedHonorarios.includes(honorario.id))
        .map((honorario) => ({
          cantidad: "1",
          producto: {
            descripcion: honorario.text,
            unidad_bulto: "1",
            lista_precios: "standard",
            codigo: "1",
            precio_unitario_sin_iva: honorario.value.toString(),
            alicuota: "0",
          },
        }));

      const facturaAmount = detalleFactura.reduce(
        (total, item) =>
          total + parseFloat(item.producto.precio_unitario_sin_iva),
        0
      );

      const response = await generarFacturaC(
        siniestroId,
        detalleFactura,
        compañiaAseguradora,
        rubro
      );

      if (response.success && response.data && response.data.comprobante_nro) {
        const facturaCompleta = response.data.comprobante_nro;
        const partes = facturaCompleta.split("-");
        const facturaNumber =
          partes.length > 1
            ? parseInt(partes[1], 10)
            : parseInt(facturaCompleta, 10);

        const newFactura = {
          incident_id: siniestroId,
          number: facturaNumber,
          amount: facturaAmount,
          status: "N",
          punto_venta: "00006",
        };

        try {
          const addedFacturaResponse = await addFactura(newFactura);
          if (addedFacturaResponse.success && addedFacturaResponse.data) {
            await addAccionToSiniestro(siniestroId, 54, null, null);
            onFacturaGenerated(addedFacturaResponse.data);
            updatePdfExistence(response.data.comprobante_nro);
            toast.success("Factura generada y agregada con éxito");

            // Extraer punto de venta y número de factura
            const [puntoVenta, numeroFactura] =
              response.data.comprobante_nro.split("-");
            const pdfUrl = `${BASE_URL}/api/facturas/descargar/${puntoVenta}-${numeroFactura}`;

            try {
              const pdfResponse = await fetch(pdfUrl);
              if (pdfResponse.ok) {
                // Si la respuesta es exitosa, abre el PDF en una nueva pestaña
                window.open(pdfUrl, "_blank");
              } else {
                const errorData = await pdfResponse.json();
                console.error("Error al descargar el PDF:", errorData);
                toast.error(
                  `Error al descargar el PDF: ${JSON.stringify(errorData)}`
                );
              }
            } catch (error) {
              console.error("Error al descargar el PDF:", error);
              if (error instanceof Error) {
                toast.error(`Error al descargar el PDF: ${error.message}`);
              } else {
                toast.error("Error desconocido al descargar el PDF");
              }
            }
          }
        } catch (addError) {
          console.error("Error al agregar la factura al sistema:", addError);
          if (
            addError instanceof Error &&
            addError.message.includes(
              "Ya existe una factura activa con ese número"
            )
          ) {
            toast.warning(
              "La factura ya existe en el sistema. Se abrirá el PDF generado."
            );
            // Aún así, abrimos el PDF usando la URL completa
            const pdfFileName = `factura_${response.data.comprobante_nro}.pdf`;
            const pdfUrl = `${BASE_URL}/api/facturas/descargar/${pdfFileName}`;
            window.open(pdfUrl, "_blank");
          } else {
            toast.error(
              "La factura se generó pero no se pudo agregar al sistema. Por favor, revise manualmente."
            );
          }
        }

        setIsOpen(false);
      } else {
        throw new Error(response.error || "Error al generar la factura");
      }
    } catch (error) {
      console.error("Error al generar o agregar la factura:", error);
      toast.error(
        `Error: ${error instanceof Error ? error.message : "Error desconocido"}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="orderRequest">Generar factura</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Generar factura</DialogTitle>
        </DialogHeader>
        <div className="mt-4">
          <p>Seleccione los honorarios que desea incluir en la factura:</p>
          <div className="mt-2 space-y-2">
            {honorarios.map((honorario) => (
              <div key={honorario.id} className="flex items-center space-x-2">
                <Checkbox
                  id={`honorario-${honorario.id}`}
                  checked={selectedHonorarios.includes(honorario.id)}
                  onCheckedChange={() => handleHonorarioChange(honorario.id)}
                />
                <label
                  htmlFor={`honorario-${honorario.id}`}
                  className="text-sm"
                >
                  {honorario.text} - ${honorario.value}
                </label>
              </div>
            ))}
          </div>
          <div className="mt-4">
            <p>Seleccione el tipo de rubro:</p>
            <RadioGroup value={rubro} onValueChange={handleRubroChange}>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="Servicios" id="servicios" />
                <Label htmlFor="servicios">Servicios</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="Productos" id="productos" />
                <Label htmlFor="productos">Productos</Label>
              </div>
            </RadioGroup>
          </div>
          <div className="mt-4 flex justify-end space-x-2">
            <Button variant="outline" onClick={() => setIsOpen(false)}>
              Cancelar
            </Button>
            <Button
              onClick={handleGenerarFactura}
              disabled={isLoading || selectedHonorarios.length === 0}
            >
              {isLoading ? "Generando..." : "Generar Factura"}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
