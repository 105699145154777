import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { formattedValueARS } from "../SiniestroDetails/formattedValue";
import { toast } from "sonner";
import { Separator } from "@/components/ui/separator";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

interface ConfirmacionDialogProps {
  bienes: { id: number; type: string; brand: string }[];
  onClose: () => void;
  sumaAsegurada: number;
  coberturas: {
    nombre_cobertura: string;
    suma_poliza: number;
    observaciones?: string;
  }[];
}

const ConfirmacionDialog: React.FC<ConfirmacionDialogProps> = ({
  bienes,
  onClose,
  sumaAsegurada,
  coberturas,
}) => {
  const [valor, setValor] = useState<string>("");
  const [selectedBienes, setSelectedBienes] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState<boolean>(true); // Control de visibilidad del alert

  const handleBienChange = (bien: string) => {
    setSelectedBienes((prevBienes) =>
      prevBienes.includes(bien)
        ? prevBienes.filter((item) => item !== bien)
        : [...prevBienes, bien]
    );
  };

  const handleValorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const numericValue = Number(inputValue);

    const coberturaMax = Math.max(...coberturas.map((c) => c.suma_poliza));
    const maxAllowedValue = Math.min(sumaAsegurada, coberturaMax);

    if (numericValue <= maxAllowedValue) {
      setValor(inputValue);
      setErrorMessage(null);
    } else {
      setErrorMessage(
        `El valor ingresado no puede superar la suma asegurada de ${formattedValueARS(
          sumaAsegurada
        )} o el monto máximo de las coberturas de ${formattedValueARS(
          coberturaMax
        )}.`
      );
    }
  };

  const formattedBienes =
    selectedBienes.length > 0
      ? selectedBienes.length > 1
        ? selectedBienes.slice(0, -1).join(", ") +
          " y " +
          selectedBienes[selectedBienes.length - 1]
        : selectedBienes[0]
      : "su bien asegurado"; // Mensaje predeterminado si no hay bienes seleccionados

  const valorFormateado = formattedValueARS(Number(valor)) || "$ 0,00";
  const sumaAseguradaFormateada = formattedValueARS(sumaAsegurada);

  const textoConfirmacion = `Estimado/a,

  Buenos días. Le informamos que se ha aprobado una transferencia bancaria por un monto de ${valorFormateado} en concepto de indemnización por los daños sufridos en ${formattedBienes}.
  
  Para proceder con el pago, necesitamos que confirme su conformidad respondiendo este mensaje y proporcionando los siguientes datos bancarios:
  
  - Cuenta Bancaria
  - Nombre del Titular
  - CUIT/CUIL
  - Constancia de CBU emitida por la entidad bancaria (puede ser obtenida a través de home banking o cajero automático)
  
  Es importante destacar que los datos bancarios deben coincidir con el titular de la póliza. En caso de que falte alguno de los datos solicitados o se proporcione un CBU a nombre de otra persona, la compañía de seguros no podrá efectuar el pago.
  
  Quedamos a la espera de su respuesta.
  
  Saludos cordiales.`;

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent className="shadow-lg overflow-y-scroll max-h-screen">
        <DialogHeader>
          <DialogTitle>Creación de conformidad</DialogTitle>
        </DialogHeader>

        {showAlert && ( // Mostrar alert solo si showAlert es true
          <Alert
            variant="default"
            className="mb-4 flex items-start space-x-2 p-4 bg-yellow-50 border-l-4 border-yellow-400"
          >
            <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" />
            <div className="flex-1">
              <AlertTitle className="text-yellow-800 font-bold">OJO</AlertTitle>
              <AlertDescription className="text-yellow-700 font-semibold">
                Este texto se utiliza para enviar por WhatsApp y es válido solo
                para Allianz (más de $2.000.000 se sugiere enviar conformidad
                PDF), La Holando, Provincia (hasta $600.000) y Meridional (hasta
                $100.000).
              </AlertDescription>
            </div>
            <Button
              variant="ghost"
              size="sm"
              className="ml-auto"
              onClick={() => setShowAlert(false)} // Ocultar alert
            >
              X
            </Button>
          </Alert>
        )}

        <div className="space-y-4">
          {sumaAsegurada && (
            <>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label className="text-right">Suma Asegurada:</Label>
                <div className="col-span-3">{sumaAseguradaFormateada}</div>
              </div>
              <Separator className="my-4" />
            </>
          )}
          {coberturas && coberturas.length > 0 && (
            <>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label className="text-right">Coberturas:</Label>
                <div className="col-span-3">
                  {coberturas.map((cobertura, index) => (
                    <div key={index} className="mb-2">
                      <p className="font-semibold">
                        {cobertura.nombre_cobertura}
                      </p>
                      <p>
                        Suma Asegurada:{" "}
                        {formattedValueARS(cobertura.suma_poliza)}
                      </p>
                      {cobertura.observaciones && (
                        <p>Observaciones: {cobertura.observaciones}</p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <Separator className="my-4" />
            </>
          )}

          <div className="space-y-4">
            <div>
              <Label>Indique el monto para la conformidad:</Label>
              <Input
                type="text"
                value={valor}
                onChange={handleValorChange}
                placeholder="Ingrese el monto en pesos"
                className="mt-2"
              />
            </div>

            <div className="mt-4">
              <Label>
                Seleccione los bienes asegurados para la conformidad: (podés
                seleccionar 1 o más)
              </Label>
            </div>
          </div>

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="mt-2 w-full">
                Seleccione bienes
              </Button>
            </DropdownMenuTrigger>

            <DropdownMenuContent className="w-56">
              <DropdownMenuLabel>Bienes</DropdownMenuLabel>
              {bienes.map((bien) => (
                <DropdownMenuCheckboxItem
                  key={bien.id}
                  checked={selectedBienes.includes(
                    `${bien.type} - ${bien.brand}`
                  )}
                  onCheckedChange={() =>
                    handleBienChange(`${bien.type} - ${bien.brand}`)
                  }
                >
                  {bien.type} - {bien.brand}
                </DropdownMenuCheckboxItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
          <Separator className="my-4" />
          <div className="mt-6">
            <Label>Texto de conformidad:</Label>
          </div>

          <Textarea
            value={textoConfirmacion}
            readOnly
            rows={8}
            className="mt-4 p-4 border rounded-md"
          />
          {errorMessage && (
            <p className="mt-4 text-red-500 font-semibold">{errorMessage}</p>
          )}
        </div>
        <DialogFooter>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(textoConfirmacion);
              toast.success("Texto copiado al portapapeles");
            }}
            disabled={!!errorMessage}
          >
            Copiar texto
          </Button>
          <Button variant="outline" onClick={onClose}>
            Cerrar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmacionDialog;
