const BASE_URL =
  import.meta.env.MODE === "production"
    ? import.meta.env.VITE_API_URL_PROD
    : import.meta.env.VITE_API_URL_DEV;

export const fetchInstancias = async () => {
  try {
    const response = await fetch(`${BASE_URL}/api/instancias`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Failed to fetch instancias");
    }

    const data = await response.json();
    return data.instancias; // Extraemos el arreglo de instancias del objeto retornado
  } catch (error) {
    throw error;
  }
};

export const createInstancia = async (values: any) => {
  try {
    const response = await fetch(`${BASE_URL}/api/instancias`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(values),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateInstancia = async (instanciaId: number, values: any) => {
  try {
    const response = await fetch(`${BASE_URL}/api/instancias/${instanciaId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(values),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteInstancia = async (instanciaId: number, token: string) => {
  try {
    const response = await fetch(`${BASE_URL}/api/instancias/${instanciaId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getInstancia = async (instanciaId: number) => {
  try {
    const response = await fetch(`${BASE_URL}/api/instancias/${instanciaId}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const checkInstanciaNameExists = async (name: string, token: string) => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/instancias/check-name?name=${name}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 403) {
      throw new Error("USER_DISABLED");
    }

    if (!response.ok) {
      throw new Error("Failed to check instancia name");
    }

    const data = await response.json();
    return data.exists;
  } catch (error) {
    throw error;
  }
};
