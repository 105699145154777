import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "@/components/ui/dialog";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useState } from "react";
import { MaterialSymbolsAddChartOutline } from "@/components/icons/MaterialSymbolsAddChartOutline";
import { Label } from "@/components/ui/label";
import { addAccionToSiniestro } from "@/services/siniestroService";
import { toast } from "sonner";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface AddFacturaDialogProps {
  onSave: (newFactura: {
    incident_id: number;
    number: number;
    amount: number;
    status: string;
    punto_venta: string;
  }) => Promise<void>;
  incidentId: number;
  disabled?: boolean;
}

export function AddFacturaDialog({
  onSave,
  incidentId,
  disabled = false,
}: AddFacturaDialogProps) {
  const [numberInput, setNumberInput] = useState("");
  const [amountInput, setAmountInput] = useState("");
  const [statusInput] = useState("N");
  const [puntoVentaInput, setPuntoVentaInput] = useState("");

  // Añade un estado para controlar el cierre del modal
  const [open, setOpen] = useState(false);

  const handleSave = async () => {
    const newFactura: FacturaInput = {
      incident_id: incidentId,
      number: Number(numberInput),
      amount: Number(amountInput),
      status: statusInput,
      punto_venta: puntoVentaInput,
    };

    try {
      await onSave(newFactura);
      await addAccionToSiniestro(incidentId, 54, null, null);

      // Restablecer los valores del formulario
      setNumberInput("");
      setAmountInput("");

      // Cerrar el modal
      setOpen(false);

      toast.success("Factura creada exitosamente.");
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : (error as any)?.response?.data?.message ||
            "Ocurrió un error al intentar crear la factura.";

      toast.error(`Error al crear la factura: ${errorMessage}`);
    }
  };

  const handleAmountInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const cleanedValue = value.replace(/[^0-9]/g, ""); // Eliminar todo excepto dígitos
    setAmountInput(cleanedValue);
  };

  const handleNumberInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const cleanedValue = value.replace(/[^0-9]/g, ""); // Eliminar todo excepto dígitos
    setNumberInput(cleanedValue);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="green" disabled={disabled}>
          <MaterialSymbolsAddChartOutline className="w-5 h-5" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Agregar factura</DialogTitle>
          <DialogDescription>
            Ingresa los detalles de la nueva factura y haz clic en guardar.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <Label htmlFor="puntoVentaInput">Punto de Venta</Label>
          <Select onValueChange={setPuntoVentaInput} value={puntoVentaInput}>
            <SelectTrigger>
              <SelectValue placeholder="Selecciona un punto de venta" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="00003">00003</SelectItem>
              <SelectItem value="00004">00004</SelectItem>
              <SelectItem value="00006">00006</SelectItem>
            </SelectContent>
          </Select>
          <Label htmlFor="numberInput">Número de Factura</Label>
          <Input
            id="numberInput"
            type="text"
            value={numberInput}
            onChange={handleNumberInputChange}
          />

          <Label htmlFor="amountInput">Monto</Label>
          <Input
            id="amountInput"
            type="text"
            value={amountInput}
            onChange={handleAmountInputChange}
          />
        </div>
        <DialogFooter>
          <Button onClick={handleSave}>Guardar</Button>
          <DialogClose asChild>
            <Button variant="secondary">Cerrar</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
