// Importaciones necesarias
import { type ColumnDef } from "@tanstack/react-table";
import { Badge } from "../ui/badge";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { MdiCheckboxMarked } from "../icons/MdiCheckboxMarked";
import { changeStatusFactura } from "@/services/facturaService";
import { Button } from "../ui/button";
import { MoreHorizontal } from "lucide-react";
import { addAccionToSiniestro } from "@/services/siniestroService";
import { toast } from "sonner";
import { Checkbox } from "../ui/checkbox";

// Definición de columnas para la tabla de facturas
export const FacturaColumns = (
  updateFactura: (invoiceId: number, newStatus: any) => void
): Array<ColumnDef<Factura>> => {
  const handleInvoiceStatusChange = async (
    invoiceId: number,
    newStatus: string,
    siniestroId: number
  ) => {
    try {
      // Cambiar el estado de la factura
      await changeStatusFactura(invoiceId, newStatus);
      updateFactura(invoiceId, newStatus); // Actualizar el estado local
      // Determinar el idActionType en función del nuevo estado
      const idActionType = newStatus === "P" ? 51 : 50;

      // Llamar a addAccionToSiniestro
      // Se asume que userAssignedId y itemId no son necesarios para esta acción
      await addAccionToSiniestro(siniestroId, idActionType, null, null);
      toast.success("Estado de factura actualizado correctamente");
    } catch (error) {
      toast.error("Error al actualizar la factura");
    }
  };

  return [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "incident_id",
      header: "Nº Stro Interno",
    },
    {
      accessorKey: "companyIncidentNumber",
      header: "Nº Siniestro Compañía",
      // Si no necesitas una celda personalizada, puedes omitir la propiedad 'cell'
    },
    {
      accessorKey: "companyName",
      header: "Nombre de Compañía",
      // Si no necesitas una celda personalizada, puedes omitir la propiedad 'cell'
    },
    {
      accessorKey: "number",
      header: "Número de Factura",
    },
    {
      accessorKey: "punto_venta",
      header: "Punto de Venta",
      cell: ({ row }) => {
        const puntoVenta = row.getValue("punto_venta") as string;
        return <div className="text-left font-medium">{puntoVenta}</div>;
      },
    },
    {
      accessorKey: "amount",
      header: "Monto",
      cell: (info) => {
        const amount = info.getValue() as number;
        return new Intl.NumberFormat("es-AR", {
          style: "currency",
          currency: "ARS",
        }).format(amount);
      },
    },
    {
      accessorKey: "amount",
      header: "Monto con IVA",
      cell: (info) => {
        const amount = info.getValue() as number;
        const amountIVA = amount * 1.21;
        return new Intl.NumberFormat("es-AR", {
          style: "currency",
          currency: "ARS",
        }).format(amountIVA);
      },
    },
    {
      accessorKey: "date",
      header: "Fecha",
      cell: (info) => {
        const dateValue = info.getValue() as string;
        const date = new Date(dateValue);
        return new Intl.DateTimeFormat("es-AR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(date);
      },
    },
    {
      accessorKey: "status",
      header: "Estado",
      cell: (info) => {
        const status = info.getValue();

        if (status === "P") {
          return <Badge variant="success">Pagado</Badge>;
        }
        if (status === "N") {
          return <Badge variant="destructive">Pendiente</Badge>;
        }

        // Retorna un Badge por defecto o null si necesitas manejar otros estados
        return <Badge variant="default">Desconocido</Badge>;
      },
    },
    {
      accessorKey: "status",
      header: "Cambiar estado",
      cell: ({ row }) => (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Abrir menú</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent>
            <DropdownMenuItem
              onSelect={() => {
                void handleInvoiceStatusChange(
                  row.original.id,
                  "P",
                  row.original.incident_id
                );
              }}
            >
              Pagado
              {row.original.status === "P" && (
                <MdiCheckboxMarked className="w-4 h-4 ml-1" />
              )}
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={() => {
                void handleInvoiceStatusChange(
                  row.original.id,
                  "N",
                  row.original.incident_id
                );
              }}
            >
              Pendiente
              {row.original.status === "N" && (
                <MdiCheckboxMarked className="w-4 h-4 ml-1" />
              )}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      ),
    },
  ];
};
