import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { Skeleton } from "@/components/ui/skeleton";

interface TodaySiniestrosBarChartProps {
  data: Array<{
    companyId: number;
    companyName: string;
    newSiniestros: number;
  }>;
  isLoading: boolean;
}

const TodaySiniestrosBarChart: React.FC<TodaySiniestrosBarChartProps> = ({
  data,
  isLoading,
}) => {
  const chartConfig = {
    newSiniestros: {
      label: "Nuevos stros.",
      color: "hsl(var(--primary))",
    },
  } satisfies ChartConfig;

  const CustomXAxisTick = (props: any) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="middle"
          fill="#666"
          style={{ fontSize: 12 }}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  const maxSiniestros = Math.max(...data.map((item) => item.newSiniestros), 0);

  // Calcular los ticks de manera segura
  const yAxisTicks = () => {
    if (maxSiniestros <= 0) return [0];
    if (maxSiniestros > 10) {
      const step = Math.ceil(maxSiniestros / 5);
      return Array.from({ length: 6 }, (_, i) => i * step);
    }
    return Array.from({ length: maxSiniestros + 1 }, (_, i) => i);
  };

  return (
    <>
      {isLoading ? (
        <Skeleton className="h-[300px] w-full" />
      ) : (
        <ChartContainer config={chartConfig}>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={data}
              margin={{ left: 20, right: 20, top: 20, bottom: 60 }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="companyName"
                tickLine={false}
                axisLine={false}
                tick={<CustomXAxisTick />}
                height={60}
              />
              <YAxis
                tickLine={false}
                axisLine={false}
                domain={[0, "dataMax"]}
                ticks={yAxisTicks()}
              />
              <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
              <Bar
                dataKey="newSiniestros"
                fill="rgb(52, 152, 219)"
                radius={[4, 4, 0, 0]}
              />
            </BarChart>
          </ResponsiveContainer>
        </ChartContainer>
      )}
    </>
  );
};

export default TodaySiniestrosBarChart;
