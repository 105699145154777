import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import { CheckIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { ScrollArea } from "@/components/ui/scroll-area";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { ButtonLoading } from "@/components/ui/ButtonLoading";
import { apiFetch } from "@/services/siniestroService";
import { useDireccionStore } from "@/stores/formStore";
import { Dialog, DialogTrigger, DialogContent } from "@/components/ui/dialog";

interface FormValues {
  id: number;
  insured_id: number;
  province_id: string;
  street: string;
  number: string;
  floor?: string;
  apartment?: string;
  zipcode?: string;
  locality: string;
  telephone?: string;
  cellphone?: string;
  phonepas?: string;
}

const formSchema = z.object({
  province_id: z.string().min(1, "La provincia es requerida"),
  street: z.string().min(1, "La calle es requerida"),
  number: z.string().min(1, "El número es requerido"),
  floor: z.string().optional(),
  apartment: z.string().optional(),
  zipcode: z
    .string()
    .optional()
    .refine((val) => {
      if (val === undefined || val === "") {
        return true; // Acepta valores vacíos ya que el campo es opcional
      }
      const num = parseInt(val, 10);
      return !isNaN(num) && num >= 0 && num <= 32767;
    }, "El código postal debe estar entre 0 y 32767"),
  locality: z.string().min(1, "La localidad es requerida"),
  telephone: z.string().optional(),
  cellphone: z.string().optional(),
  phonepas: z.string().optional(),
});

export function DireccionForm() {
  const { direccionInfo, setDireccionInfo } = useDireccionStore();
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [existingAddresses, setExistingAddresses] = useState<FormValues[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<FormValues | null>(
    null
  );
  const [isCreatingNewAddress, setIsCreatingNewAddress] = useState(false);
  const [, setIsNewInsured] = useState(false); // Nuevo estado para determinar si el asegurado es nuevo
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      id: 0, // Agrega id
      insured_id: 0, // Agrega insured_id
      province_id: "",
      street: "",
      number: "",
      floor: "",
      apartment: "",
      zipcode: "",
      locality: "",
      telephone: "",
      cellphone: "",
      phonepas: "",
    },
  });

  useEffect(() => {
    // Llamada a la API para obtener las provincias
    apiFetch(`/api/direcciones/provinces`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then(async (res) => await res.json())
      .then((data) => {
        const sortedData = data.sort(
          (a: { name: string }, b: { name: string }) =>
            a.name.localeCompare(b.name)
        );
        setProvinces(sortedData);
      })
      .catch((error) => {
        console.error("Error al obtener provincias:", error);
      });

    // Llamada a la API para obtener direcciones existentes del asegurado
    if (direccionInfo?.insured_id) {
      apiFetch(`/api/direcciones/asegurado/${direccionInfo.insured_id}`, {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      })
        .then(async (res) => await res.json())
        .then((data) => {
          setExistingAddresses(data);
          setIsNewInsured(data.length === 0); // Determina si el asegurado es nuevo
          if (data.length > 0) {
            setIsDialogOpen(true); // Abre el diálogo si hay direcciones existentes
          }
        })
        .catch((error) => {
          console.error("Error al obtener direcciones del asegurado:", error);
        });
    }
  }, [direccionInfo?.insured_id]);

  const onSubmit = async (data: FormValues) => {
    setIsSaving(true);
    try {
      const response = await apiFetch(`/api/direcciones`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      setDireccionInfo(result);
      toast.success("Dirección creada correctamente");
      navigate("/nuevo-siniestro/nuevo-siniestro");
    } catch (error) {
      console.error("Error al crear dirección:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleUseExistingAddress = (address: FormValues) => {
    form.reset(address);
    setSelectedAddress(address);
    setIsDialogOpen(false);
    setIsCreatingNewAddress(false);
  };

  const handleNextStep = () => {
    if (selectedAddress) {
      const updatedAddress = {
        ...selectedAddress,
        id: selectedAddress.id || 0, // Proporciona un valor por defecto para id
        insured_id: direccionInfo?.insured_id || 0, // Usa insured_id de direccionInfo o un valor por defecto
      };
      setDireccionInfo(updatedAddress);
      navigate("/nuevo-siniestro/nuevo-siniestro");
    }
  };

  return (
    <div className="flex flex-col md:flex-row items-start">
      <Dialog
        open={isDialogOpen && !isCreatingNewAddress}
        onOpenChange={setIsDialogOpen}
      >
        <DialogTrigger asChild>
          <Button className="hidden">Open Dialog</Button>
        </DialogTrigger>
        <DialogContent className="max-h-[600px] overflow-y-auto">
          <h2 className="text-xl font-bold mb-4">Direcciones existentes</h2>
          <p className="mb-4">
            El usuario ya tiene las siguientes direcciones. ¿Quieres usar alguna
            de ellas?
          </p>
          <ScrollArea className="h-[300px]">
            {existingAddresses.map((address, index) => (
              <Card
                key={index}
                className="address-option mb-2 cursor-pointer hover:bg-gray-100"
                onClick={() => handleUseExistingAddress(address)}
              >
                <CardHeader>
                  <CardTitle>Dirección {index + 1}</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="flex items-center gap-2">
                    <div>
                      <p>
                        <strong>Calle:</strong> {address.street}
                      </p>
                      <p>
                        <strong>Número:</strong> {address.number}
                      </p>
                      {address.floor && (
                        <p>
                          <strong>Piso:</strong> {address.floor}
                        </p>
                      )}
                      {address.apartment && (
                        <p>
                          <strong>Departamento:</strong> {address.apartment}
                        </p>
                      )}
                      <p>
                        <strong>Localidad:</strong> {address.locality}
                      </p>
                      <p>
                        <strong>Provincia:</strong>{" "}
                        {
                          provinces.find(
                            (province) =>
                              province.id === Number(address.province_id)
                          )?.name
                        }
                      </p>
                      {address.zipcode && (
                        <p>
                          <strong>Código Postal:</strong> {address.zipcode}
                        </p>
                      )}
                      {address.telephone && (
                        <p>
                          <strong>Teléfono:</strong> {address.telephone}
                        </p>
                      )}
                      {address.cellphone && (
                        <p>
                          <strong>Celular:</strong> {address.cellphone}
                        </p>
                      )}
                      {address.phonepas && (
                        <p>
                          <strong>Teléfono PAS:</strong> {address.phonepas}
                        </p>
                      )}
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </ScrollArea>
          <Button
            onClick={() => {
              setIsDialogOpen(false);
              setIsCreatingNewAddress(true); // Enable form when creating new address
            }}
            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white"
          >
            No, quiero crear una nueva dirección
          </Button>
        </DialogContent>
      </Dialog>
      <div className="flex-1 p-4">
        <h1 className="text-2xl font-bold mb-4 dark:text-white">Dirección</h1>
        <p className="text-gray-500 mb-8 dark:text-gray-300">
          Ingrese los datos de la dirección del siniestro.
        </p>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-6 bg-white dark:bg-gray-800 p-8 rounded-lg"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="street"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Calle</FormLabel>
                    <FormControl>
                      <Input {...field} disabled={!!selectedAddress} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="number"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Número</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        onChange={(e) => field.onChange(e.target.value.trim())}
                        disabled={!!selectedAddress}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="floor"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Piso</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        onChange={(e) => field.onChange(e.target.value.trim())}
                        disabled={!!selectedAddress}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="apartment"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Departamento</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        onChange={(e) => field.onChange(e.target.value.trim())}
                        disabled={!!selectedAddress}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Controller
                control={form.control}
                name="province_id"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Provincia</FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant="outline"
                            role="combobox"
                            className="w-full justify-between"
                            disabled={!!selectedAddress}
                          >
                            {field.value
                              ? provinces.find(
                                  (province) =>
                                    province.id.toString() === field.value
                                )?.name
                              : "Seleccionar provincia"}
                            <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent asChild className="w-full p-0">
                        <Command>
                          <CommandInput
                            placeholder="Buscar provincia..."
                            className="h-9"
                          />
                          <ScrollArea className="h-[200px]">
                            {provinces.length === 0 ? (
                              <CommandEmpty>
                                No hay provincias disponibles
                              </CommandEmpty>
                            ) : (
                              <CommandGroup>
                                {provinces.map((province) => (
                                  <CommandItem
                                    key={province.id}
                                    value={province.id.toString()}
                                    onSelect={(currentValue) => {
                                      field.onChange(currentValue);
                                    }}
                                  >
                                    {province.name}
                                    <CheckIcon
                                      className={cn(
                                        "ml-auto h-4 w-4",
                                        province.id.toString() === field.value
                                          ? "opacity-100"
                                          : "opacity-0"
                                      )}
                                    />
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            )}
                          </ScrollArea>
                        </Command>
                      </PopoverContent>
                    </Popover>
                    <FormMessage>
                      {form.formState.errors.province_id?.message}
                    </FormMessage>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="zipcode"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Código Postal</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        onChange={(e) => field.onChange(e.target.value.trim())}
                        disabled={!!selectedAddress}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="locality"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Localidad</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        onChange={(e) => field.onChange(e.target.value.trim())}
                        disabled={!!selectedAddress}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="telephone"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Teléfono</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        disabled={!!selectedAddress}
                        onChange={(e) => {
                          const value = e.target.value.replace(/-/g, "");
                          field.onChange(value);
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="cellphone"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Celular</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        disabled={!!selectedAddress}
                        onChange={(e) => {
                          const value = e.target.value.replace(/-/g, "");
                          field.onChange(value);
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="phonepas"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Teléfono PAS</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        onChange={(e) => field.onChange(e.target.value.trim())}
                        disabled={!!selectedAddress}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="mt-4 flex justify-center">
              {isSaving && !!selectedAddress ? (
                <ButtonLoading />
              ) : (
                <Button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  disabled={!!selectedAddress} // Deshabilitar el botón si selectedAddress existe
                >
                  Crear dirección
                </Button>
              )}
            </div>
          </form>
        </Form>
        {selectedAddress && (
          <div className="mt-4 flex justify-center">
            <Button
              onClick={handleNextStep}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Avanzar al siguiente paso
            </Button>
          </div>
        )}
      </div>
      {selectedAddress && (
        <Card className="flex-1 mt-16 ml-16 max-w-sm">
          <CardHeader>
            <CardTitle>Información de la dirección a usar</CardTitle>
          </CardHeader>
          <CardContent>
            {selectedAddress.street && (
              <p>
                <strong>Calle:</strong> {selectedAddress.street}
              </p>
            )}
            {selectedAddress.number && (
              <p>
                <strong>Número:</strong> {selectedAddress.number}
              </p>
            )}
            {selectedAddress.floor && (
              <p>
                <strong>Piso:</strong> {selectedAddress.floor}
              </p>
            )}
            {selectedAddress.apartment && (
              <p>
                <strong>Departamento:</strong> {selectedAddress.apartment}
              </p>
            )}
            {selectedAddress.locality && (
              <p>
                <strong>Localidad:</strong> {selectedAddress.locality}
              </p>
            )}
            {selectedAddress.province_id && (
              <p>
                <strong>Provincia:</strong>{" "}
                {
                  provinces.find(
                    (province) =>
                      province.id === Number(selectedAddress.province_id)
                  )?.name
                }
              </p>
            )}
            {selectedAddress.zipcode && (
              <p>
                <strong>Código Postal:</strong> {selectedAddress.zipcode}
              </p>
            )}
            {selectedAddress.telephone && (
              <p>
                <strong>Teléfono:</strong> {selectedAddress.telephone}
              </p>
            )}
            {selectedAddress.cellphone && (
              <p>
                <strong>Celular:</strong> {selectedAddress.cellphone}
              </p>
            )}
            {selectedAddress.phonepas && (
              <p>
                <strong>Teléfono PAS:</strong> {selectedAddress.phonepas}
              </p>
            )}
          </CardContent>
        </Card>
      )}
    </div>
  );
}
