import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import SiniestrosAreaChart from "./SiniestrosAreaChart";
import TodaySiniestrosBarChart from "./TodaySiniestrosBarChart";

interface CombinedSiniestrosChartProps {
  last10DaysData: any[];
  todaySiniestrosData: any[];
  isLoading: boolean;
}

const CombinedSiniestrosChart: React.FC<CombinedSiniestrosChartProps> = ({
  last10DaysData,
  todaySiniestrosData,
  isLoading,
}) => {
  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Resumen de Siniestros</CardTitle>
        <CardDescription>Últimos 10 días y hoy por compañía</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col lg:flex-row gap-4">
          <div className="w-full lg:w-1/2">
            <h3 className="text-lg font-semibold mb-2">Últimos 10 días</h3>
            <SiniestrosAreaChart data={last10DaysData} isLoading={isLoading} />
          </div>
          <div className="w-full lg:w-1/2">
            <h3 className="text-lg font-semibold mb-2">Hoy por compañía</h3>
            <TodaySiniestrosBarChart
              data={todaySiniestrosData}
              isLoading={isLoading}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default CombinedSiniestrosChart;
