import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { apiFetch } from "@/services/siniestroService";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import {
  Select,
  SelectItem,
  SelectContent,
  SelectValue,
  SelectTrigger,
} from "@/components/ui/select";

interface RioUruguayConformityDialogProps {
  siniestro: {
    companyIncidentNumber: string;
    insuredName: string;
    policyNumber: string;
    ocurrenceDatetime: string;
    occurrenceStreet: string;
    occurrenceNumber: string;
    occurrenceLocality: string;
  };
  onClose: () => void;
}

const RioUruguayConformityDialog: React.FC<RioUruguayConformityDialogProps> = ({
  siniestro,
  onClose,
}) => {
  const [monto, setMonto] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [tipoPago, setTipoPago] = useState<"transferencia" | "ordenCompra">(
    "transferencia"
  );
  const [comercioSeleccionado, setComercioSeleccionado] = useState<string>("");

  const comerciosOptions = ["FRÁVEGA", "MUSIMUNDO", "AUTHOGAR"];

  const handleExportPdf = async () => {
    setIsLoading(true);
    try {
      const response = await apiFetch("/api/pdf/rio-uruguay-conformity", {
        method: "POST",
        body: JSON.stringify({
          siniestroNumber: siniestro.companyIncidentNumber,
          asegurado: siniestro.insuredName,
          polizaNumber: siniestro.policyNumber,
          monto: parseFloat(monto),
          fechaSiniestro: new Date(
            siniestro.ocurrenceDatetime
          ).toLocaleDateString("es-AR"),
          ciudadSiniestro: `${siniestro.occurrenceStreet} ${siniestro.occurrenceNumber}, ${siniestro.occurrenceLocality}`,
          tipoPago,
          comercioOrdenCompra:
            tipoPago === "ordenCompra" ? comercioSeleccionado : undefined,
        }),
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Conformidad_RioUruguay_${siniestro.companyIncidentNumber}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error("Failed to generate PDF");
      }
    } catch (error) {
      console.error("Error exporting PDF:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Generar conformidad Rio Uruguay</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <div>
            <Label>Monto de la conformidad:</Label>
            <Input
              type="number"
              min="0"
              step="0.01"
              value={monto}
              onChange={(e) => {
                const value = e.target.value;
                if (
                  value === "" ||
                  (/^\d*\.?\d*$/.test(value) && parseFloat(value) >= 0)
                ) {
                  setMonto(value);
                }
              }}
              placeholder="Ingrese el monto en pesos"
            />
          </div>

          <div>
            <Label>Tipo de pago:</Label>
            <Select
              onValueChange={(value: "transferencia" | "ordenCompra") => {
                setTipoPago(value);
                setComercioSeleccionado(""); // Resetear comercio al cambiar tipo de pago
              }}
              value={tipoPago}
            >
              <SelectTrigger>
                <SelectValue placeholder="Seleccione tipo de pago" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="transferencia">
                  Transferencia Bancaria
                </SelectItem>
                <SelectItem value="ordenCompra">Orden de Compra</SelectItem>
              </SelectContent>
            </Select>
          </div>

          {tipoPago === "ordenCompra" && (
            <div>
              <Label>Comercio:</Label>
              <Select
                onValueChange={setComercioSeleccionado}
                value={comercioSeleccionado}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Seleccione comercio" />
                </SelectTrigger>
                <SelectContent>
                  {comerciosOptions.map((comercio) => (
                    <SelectItem key={comercio} value={comercio}>
                      {comercio}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          )}
        </div>
        <DialogFooter>
          <Button
            onClick={handleExportPdf}
            disabled={
              isLoading ||
              !monto ||
              parseFloat(monto) <= 0 ||
              (tipoPago === "ordenCompra" && !comercioSeleccionado)
            }
          >
            {isLoading ? (
              <>
                <AiOutlineLoading3Quarters className="mr-2 h-4 w-4 animate-spin" />
                Exportando...
              </>
            ) : (
              "Exportar PDF"
            )}
          </Button>
          <Button variant="outline" onClick={onClose}>
            Cerrar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default RioUruguayConformityDialog;
